import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { ReactComponent as CyberSecurity } from "images/bsk/cyberSecurity.svg";
import { toast } from 'react-toastify';
import { validateEmail } from "utils/index.js";
import { NODE_MAILER_API_PROD, NODE_MAILER_API_DEV } from "config";

const Container = tw.div`relative`;
const TwoColumn = styled.div`
  ${tw`flex flex-col lg:flex-row lg:items-stretch max-w-screen-xl mx-auto`}
`;
const LeftColumn = styled.div`
  border-radius: 50px;
  background-color: #1966f8;
  z-index: 1;
  height: auto;
  margin-bottom: 3.3%;

  @media (max-width: 1023px) {
    margin-top: 0;
    border-radius: 20px;
    height: auto;
  }
  ${tw`
    relative md:w-full lg:w-6/12 text-center mx-auto lg:max-w-none lg:text-left
    sm:px-16 sm:py-12 px-8 py-8
  `}
`;
const RightColumn = styled.div`
  margin-left: -40px;
  @media (max-width: 1023px) {
    margin-left: 0px;
  }
  ${tw`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`}
`;

const Heading = tw.h1`
  font-bold text-xl md:text-3xl lg:text-3xl xl:text-4xl text-white leading-tight pr-0 lg:pr-10
  md:max-w-xl lg:max-w-none mx-auto
`;
const Paragraph = tw.p`
  my-5 lg:mb-8 lg:mt-5 text-base xl:text-lg text-white
  md:max-w-xl lg:max-w-none mx-auto
`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`
      sm:pr-48 pl-8 py-3 rounded-md sm:rounded-xl border-2 w-full font-medium focus:outline-none transition duration-300 focus:border-primary-500 hover:border-gray-500
      mb-2 sm:mb-0
    `}  }
  button {
    ${tw`
      w-full sm:absolute right-0 top-0 bottom-0 bg-orange-default text-gray-100 font-bold rounded-md sm:rounded-l-none sm:rounded-r-xl py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-orange-400 transition duration-300
    `}
  }
`;

const IllustrationContainer = styled.div`
  ${tw`flex justify-center lg:justify-end items-center`}

  .security-svg {
    ${tw`w-full`}
  }
`;

export default ({ roundedHeaderButton }) => {
  const url = process.env.NODE_ENV !== "production" ? NODE_MAILER_API_DEV : NODE_MAILER_API_PROD;
  const [email, setEmail] = useState("");
  useEffect(() => {
    window.gsap.fromTo('#gPlant > *:nth-child(2) > *',{transformOrigin:'50% 100%',rotation:-3},{duration:5,delay:-5,rotation:3,stagger:{each:2.5,repeat:-1,yoyo:true},ease:'power1.inOut'})
    window.gsap.fromTo('#gPlant > *:nth-child(1) > *',{transformOrigin:'50% 100%',rotation:-3},{duration:5,delay:-5,rotation:3,stagger:{each:2.5,repeat:-1,yoyo:true},ease:'power1.inOut'})

    //	access1
    window.gsap.timeline({repeat:-1,repeatDelay:2})
      .to('#gPasswoed clipPath,#gPasswoed defs > *',{duration:0.5,scaleX:0,transformOrigin:'0% 50%',ease:'none)'})
      .to('#access1',{duration:0.25,fill:'#ED442E',ease:'none'})
      .to('#gLogin',{duration:0.5,scaleX:0,transformOrigin:'0% 50%',ease:'none'})

      .to('#gLogin',{duration:1.5,delay:1,scaleX:1,ease:'none'})
      .to('#gPasswoed clipPath,#gPasswoed defs > *',{duration:1.5,delay:1,scaleX:1,ease:'none'})
      .to('#access1',{duration:0.25,fill:'#00D569',ease:'none'})


    // access2
    window.gsap.timeline({repeat:-1,repeatDelay:2})
      .to('#access2',{duration:0.25,fill:'#00D569',ease:'none'})
      .to('#gZamok',{duration:0.5,y:-40,ease:'power1.inOut'})

      .to('#access2',{duration:0.25,delay:2,fill:'#ED442E',ease:'none'})
      .to('#gZamok',{duration:0.5,y:0,ease:'power1.inOut'})

    // graph
    window.gsap.set('#gGraph',{skewX:-6,transformOrigin:'0% 100%'})
    window.gsap.to('#gGraph > *:nth-child(1)',{duration:4,delay:0,repeatDelay:0.4,repeat:-1,yoyo:true,scaleY:0.6,transformOrigin:'50% 100%'})
    window.gsap.to('#gGraph > *:nth-child(2)',{duration:4,delay:-1,repeatDelay:0.4,repeat:-1,yoyo:true,scaleY:0.6,transformOrigin:'50% 100%'})
    window.gsap.to('#gGraph > *:nth-child(3)',{duration:4,delay:-2,repeatDelay:0.4,repeat:-1,yoyo:true,scaleY:0.6,transformOrigin:'50% 100%'})
    window.gsap.to('#gGraph > *:nth-child(4)',{duration:4,delay:-3,repeatDelay:0.4,repeat:-1,yoyo:true,scaleY:0.6,transformOrigin:'50% 100%'})
    window.gsap.to('#gGraph > *:nth-child(5)',{duration:4,delay:-4,repeatDelay:0.4,repeat:-1,yoyo:true,scaleY:0.6,transformOrigin:'50% 100%'})
    window.gsap.to('#gGraph > *:nth-child(6)',{duration:4,delay:-5,repeatDelay:0.4,repeat:-1,yoyo:true,scaleY:0.6,transformOrigin:'50% 100%'})

    // diagram
    window.gsap.timeline({repeat:-1,repeatDelay:5})
        .to('#gDiagram > *',{duration:0.5,opacity:0,ease:'none'})
        .to('#gDiagram > *:nth-child(1)',{duration:0.5,opacity:1,delay:1,ease:'none'})
        .to('#gDiagram > *:nth-child(2)',{duration:0.5,opacity:1,delay:1,ease:'none'})
        .to('#gDiagram > *:nth-child(3)',{duration:0.5,opacity:1,delay:1,ease:'none'})

    // main anim
    window.gsap.to('#mLLp',{duration:0.8,repeat:-1,yoyo:true,transformOrigin:'90% 40%',rotation:10,ease:'power1.out'})
    window.gsap.to('#mHL',{duration:0.25,repeat:-1,yoyo:true,transformOrigin:'90% 80%',rotation:5,ease:'none'})
    window.gsap.to('#mHR',{duration:0.25,delay:-0.25,repeat:-1,yoyo:true,transformOrigin:'90% 80%',rotation:5,ease:'none'})
    window.gsap.to('#mHead',{duration:1,delay:0,repeat:-1,repeatDelay:2.5,yoyo:true,transformOrigin:'60% 90%',rotation:-10,ease:'none'})
    window.gsap.to('#mGlasses',{duration:1,delay:0.2,repeat:-1,repeatDelay:2.5,yoyo:true,transformOrigin:'100% 100%',rotation:-5,ease:'none'})
    window.gsap.to('#mTail',{duration:1,delay:0.2,repeat:-1,repeatDelay:2.5,yoyo:true,transformOrigin:'0% 50%',rotation:30,ease:'none'})
    window.gsap.from('#gTextScreen1 > *',{duration:0.3,repeat:-1,opacity:0,stagger:0.4,ease:'none'})

    //// vor
    window.gsap.timeline({repeat:-1,delay:2,repeatDelay:5})
        .fromTo('#mV',{y:400},{duration:0.8,y:200,ease:'power1.out'})
        .to('#mV',{duration:0.8,delay:1.5,y:0,ease:'power1.inOut'})
        .from('#mVH',{duration:0.5,transformOrigin:'50% 95%',rotation:-140,ease:'power1.out'})

        .from('#gWarning',{duration:0.25,opacity:0,ease:'none'})
        .to('#gWarning',{duration:0.25,transformOrigin:'50% 50%',repeat:1,yoyo:true,scale:1.2,ease:'none'})
        .to('#gWarning',{duration:0.5,y:160,ease:'back.out(1.7)'})
        .to('#gWarning',{duration:0.5,opacity:0,ease:'power1.in'},'>-0.25')
        .to('#mV',{duration:0.5,y:600,ease:'power1.out'},'<')
  }, []);

  const handleAlert = () => {
    if (validateEmail(email)) {
      fetch(url, {
        method: "POST",
        body: JSON.stringify({data: email}),
        headers: { "Content-Type": "application/json" }
      }).then(result => {
        toast.info(`A representative will get in touch with you shortly at ${email}`);
      }).catch(err => {
        toast.info(`There is something wrong`);
        console.log(err)
      })
    } else {
      toast.error("Please enter valid email.");
    }
  }

  return (
    <>
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              Penetration Testing starting at $4,500.
            </Heading>
            <Paragraph>
              SOC2, HIPAA, and Vendor/Partner compliant pentests satisfy industry requirements and allow you to focus your time on what matters to your business.
            </Paragraph>
            <Actions>
              <input
                type="text"
                placeholder="Email"
                onChange={e => setEmail(e.target.value)}
                onKeyUp={e => {
                  if (e.keyCode === 13) {
                    handleAlert()
                  }
                }}
              />
              <button onClick={handleAlert}>Become Compliant</button>
            </Actions>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <CyberSecurity className="security-svg" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
      </Container>
    </>
  );
};
