import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Carousel from "components/features/Carousel.js";
import SecurityWork from "components/features/SecurityWork.js";
import ServicesOffered from "components/features/ServicesOffered.js";
import WhyBSK from "components/features/WhyBSK.js";
import Testimonials from "components/features/Testimonials.js";
import BSKLogos from "components/features/BSKLogos.js";
import Footer from "components/footers/Footer.js";
import { ToastContainer } from 'react-toastify';

export default () => {
  return (
    <>
      <Header />
      <AnimationRevealPage>
        <Hero />
        <Carousel />
        <div>
          <SecurityWork />
          <ServicesOffered />
          <WhyBSK />
        </div>
        <div>
          <BSKLogos />
          <Testimonials />
        </div>
      </AnimationRevealPage>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
