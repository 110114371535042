import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import styled from "styled-components";
import { SectionTitle } from "components/misc/Headings.js";
import Client1 from "images/bsk/client1.jpg";
import Client2 from "images/bsk/client2.jpg";
import Client3 from "images/bsk/client3.jpg";

const Container = tw.div`relative py-16 bg-primary-1100 -mx-8 px-8`;
const OneColumnContainer = styled.div`
  ${tw`mx-auto max-w-screen-xl`}
`;
const UnderLine = tw.div`flex justify-center mb-20`;
const Line = styled.div`
  ${tw`w-12 h-1`}
  background: #fba00c;
`;
const Line1 = styled.div`
  ${tw`w-12 h-1`}
  background: #73a1fd;
`;
const Line2 = styled.div`
  ${tw`w-12 h-1`}
  background: #00d569;
`;

const TestimonialSection = styled.div`
  ${tw`w-full flex md:flex-row flex-col justify-around`}
`

const Card = styled.div`
  transition: all .3s ease-in-out;
  &:hover {
    transform: translateY(-1rem);
  }
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1), 0 4px 40px 20px rgba(0,0,0,0.05);
  ${tw`flex flex-col flex-1 text-center items-center h-full mb-16 md:mb-0 sm:mx-2 md:mx-2 lg:mx-6 xl:mx-12 px-2 py-8 h-auto bg-white`}

  .imageContainer {
    margin-top: -75px;
  }

  .title {
    ${tw`tracking-wider font-bold text-black text-xl leading-none`}
  }

  .sub-title {
    ${tw`mb-5 text-lg`}
  }

  .description {
    ${tw`mt-2 font-normal text-black leading-snug px-8`}
  }
`;

export default () => {
  const list = [
    {
      src: Client1,
      title: "Matt Schulman",
      subtitle: "CEO of Pave",
      description: 'BSK Security was extremely professional and timely with their work for Trove. We are grateful for their support and plan to use them again in the future. With the help of BSK Security, we are a trusted partner to our clients.'
    },
    {
      src: Client2,
      title: "Samir Goel",
      subtitle: "Co-Founder of Esusu",
      description: 'Security is paramount to our work at Esusu to protect our client’s data and stay compliant in the highly regulated finance industry. We have always been impressed with the services of BSK Security. Their expertise, professionalism and thoroughness has allowed us to quickly identify and remediate vulnerabilities.'
    },
    {
      src: Client3,
      title: "Shaan Patel",
      subtitle: "CEO of Verix",
      description: 'Our organization regularly goes through penetration tests and everytime we go to BSK Security. We feel a great sense of comfort because of how thorough BSK Security tests our services and how they educate us on ways to remediate any issues they discover. We’ve recommended BSK Security to all our peers and we’d highly recommend them to you.'
    }
  ]
  return (
    <Container id="testimonials">
      <OneColumnContainer>
        <SectionTitle>
          What our clients say
        </SectionTitle>
        <UnderLine>
          <Line />
          <Line1 />
          <Line2 />
        </UnderLine>
        <TestimonialSection>
          {
            list.map((item, i) => (
              <Card key={i}>
                <span className="imageContainer">
                  <img css={tw`w-20 rounded-full`} src={item.src} alt="" />
                </span>
                <span className="textContainer">
                  <span className="title">{item.title}</span>
                  <p className="sub-title">{item.subtitle}</p>
                  <p className="description">
                    {item.description}
                  </p>
                </span>
              </Card>
            ))
          }
        </TestimonialSection>
      </OneColumnContainer>
    </Container>
  );
};
