import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, SubHeading } from "components/misc/Headings.js";
import CloudImage from "images/bsk/cloud.svg";
import WebImage from "images/bsk/web.svg";
import APIImage from "images/bsk/api.svg";
import MobileImage from "images/bsk/mobile.svg";

const Container = tw.div`relative py-12 md:pt-20 md:pb-32 bg-primary-1100 -mx-8 px-8`;

const TwoColumn = tw.div`relative mx-auto max-w-screen-xl`;

const Row = styled.div`
  ${tw`flex flex-col xs:flex-row flex-wrap py-0 px-1 xl:mx-24 lg:mx-12 md:mx-8`}
`;
const Column = styled.div(props => [
  tw`flex-1 py-0 px-1`,
  props.top ? tw`mt-0 xs:-mt-24 w-full xs:w-5/6` : tw`mt-0 w-full`,

  `div {
    margin-top: 8px;
    vertical-align: middle;
  }`,
  `.extra-box {
    display: none;

    @media (min-width: 1024px) {
      display: block;
    }
  }`
]);

const Bullet = styled.div
  `.checkmark {
    display:inline-block;
    width: 22px;
    height:22px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

.checkmark_circle {
    position: absolute;
    width:22px;
    height:22px;
    background-color: rgb(0, 213, 105);
    border-radius:11px;
    left:0;
    top:0;
}

.checkmark_stem {
    position: absolute;
    width:3px;
    height:9px;
    background-color:#fff;
    left:11px;
    top:6px;
}

.checkmark_kick {
    position: absolute;
    width:3px;
    height:3px;
    background-color:#fff;
    left:8px;
    top:12px;
}`;

const Margin = tw.div`mb-12 xs:mb-24`;

export default () => {
  return (
    <Container>
      <TwoColumn>
        <SectionHeading>
          Why BSK Security
        </SectionHeading>
        <SubHeading>
        Why BSK Security for Penetration Testing?
        </SubHeading>
        <Row>
          <Column style={{padding:"20px"}}>
      <ul>

            <Bullet><li>
                  <span className="checkmark"><div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div><div className="checkmark_kick"></div>
            </span>
            Experience testing in cloud-based environments like Heroku, GCP, AWS, & Azure</li></Bullet>

            <Bullet><li>
                                    <span className="checkmark">
                              <div className="checkmark_circle"></div>
                              <div className="checkmark_stem"></div>
                              <div className="checkmark_kick"></div>
                              </span>
                              Serving the most regulated industries including technology, financial services, healthcare and government</li></Bullet>

                              <Bullet><li>
                                    <span className="checkmark">
                              <div className="checkmark_circle"></div>
                              <div className="checkmark_stem"></div>
                              <div className="checkmark_kick"></div>
                              </span>
                              Industry Certified Testers from SANS Organization</li></Bullet>

      </ul>
          </Column>
          <Column style={{padding:"20px"}}>
      <ul>
            <Bullet><li>
                  <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
            </span>
            100% referral and satisfaction rate from our clients</li></Bullet>

            <Bullet><li>
                  <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
            </span>
            Competitive rates to accommodate growing enterprises</li></Bullet>

                        <Bullet><li>
                              <span className="checkmark">
                        <div className="checkmark_circle"></div>
                        <div className="checkmark_stem"></div>
                        <div className="checkmark_kick"></div>
                        </span>
                        We put you and your business first, providing communication and accessibility at all times</li></Bullet>


      </ul>
          </Column>
        </Row>
      </TwoColumn>
    </Container>
  );
};
